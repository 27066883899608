<template>
  <v-container class="pa-0 fill-height">
    <qrcode-stream :constraints="{ facingMode }" :camera="camera" @decode="onDecode" @error="onError" ref="qrcodeReader"
      v-if="!$props.usingComponentAsAnAPI">

      <v-container class="fill-height"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center;">

        <v-btn class="ma-0" @click="switchCamera" fab :disabled="scanning" style="position:fixed; right:20px;top:70px;">
          <v-icon>
            mdi-camera-flip
          </v-icon>
        </v-btn>

        <v-icon class="mt-10" size="240" color="rgb(255 255 255 / 28%)"
          v-bind:style="{ animation: scanning ? 'rotation 1s infinite linear' : '' }">
          {{ scanning ? 'mdi-loading' : 'mdi-scan-helper' }}
        </v-icon>


        <p class="text-center text-h5 mt-10" style="color:rgb(255 255 255 / 80%)">Escanea el QR de tu reserva.</p>


        <v-btn x-large class="mt-12 mb-12" rounded @click="focusCivilId" color="primary" :disabled="scanning">
          <v-icon left>
            mdi-login
          </v-icon>
          Ingreso con identificación
        </v-btn>




      </v-container>

    </qrcode-stream>


    <v-bottom-sheet v-model="showCivilId">
      <v-card>
        <v-card-title>
          Ingreso con identificación
          <v-spacer></v-spacer>
          <v-btn fab icon small @click="showCivilId = false; scanning = false; civilId = null">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="civilId" filled rounded label="Id o documento" required id="civilID"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-btn color="primary" text @click="showCivilId = false; scanning = false; civilId = null"
            rounded>CANCELAR</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="loginWithID" rounded :loading="loading" class="mb-4">
            <v-icon left>
              mdi-login
            </v-icon>
            CONTINUAR</v-btn>
        </v-card-actions>

      </v-card>
    </v-bottom-sheet>



    <v-dialog v-model="dialog">
      <v-card v-if="lastScanned" color="green" dark>

        <v-card-title>
          Bienvenido, {{ lastScanned.userName }}!!


        </v-card-title>
        <v-card-actions class="mb-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false; scanning = false">CONTINUAR</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp, serverTimestamp } from "firebase/firestore";

import { getDatabase, ref, push, set, get, child, onChildChanged, update } from "firebase/database";
import CryptoJS from 'crypto-js';
import { mask } from 'vue-the-mask'

import moment from 'moment';
import { watch } from 'vue';

import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)

export default {
  beforeDestroy(e) {
    if (this.cameraStream && !this.$props.usingComponentAsAnAPI) {
      this.cameraStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  },
  directives: { mask },
  name: 'QRCodeReader',
  components: {
    QrcodeStream
  },
  props: {
    usingComponentAsAnAPI: {
      type: Boolean,
      default: false
    },
    selectedSchedule: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      civilId: null,
      showCivilId: false,
      cameraStream: null,
      cameraReady: false,
      scanning: false,
      lastScanned: null,
      dialog: false,
      hideFinish: false,
      workoutsCompleted: 0,
      workoutDates: [],
      workoutDoc: null,
      workingOut: false,
      user: null,
      inscription: null,
      today: moment().format("YYYY-MM-DD"),
      alert: false,
      loading: false,
      cameras: [],
      selectedCameraIndex: 0,
      selectedCamera: null,
      facingMode: 'user',
      camera: null

    }
  },
  mounted() {
    this.fetchCameras();
  },
  methods: {
    async fetchCameras() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.cameras = devices.filter(device => device.kind === 'videoinput');

      //check if there is a user camera and set this.camera to 'user' or 'front' if there is a camera
      if (this.cameras.length > 0) {
        this.camera = this.facingMode === 'user' ? 'front' : 'rear';
      }

    },
    switchCamera() {
      if (this.cameras.length > 0) {
        this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
        this.camera = this.facingMode === 'user' ? 'front' : 'rear';
      }
    },
    focusCivilId() {
      this.showCivilId = true;
      this.$nextTick(() => {
        document.getElementById("civilID").focus();
      })

    },
    onError(err) {
      const cameraMissingError = error.name === 'OverconstrainedError'
      const triedFrontCamera = this.facingMode === 'user'

      if (triedFrontCamera && cameraMissingError) {
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'No se encontró la cámara frontal.',
          type: 'error'
        });
      }
      let error = `[${err.name}]: `

      if (err.name === 'NotAllowedError') {
        error += 'you need to grant camera access permission'
      } else if (err.name === 'NotFoundError') {
        error += 'no camera on this device'
      } else if (err.name === 'NotSupportedError') {
        error += 'secure context required (HTTPS, localhost)'
      } else if (err.name === 'NotReadableError') {
        error += 'is the camera already in use?'
      } else if (err.name === 'OverconstrainedError') {
        error += 'installed cameras are not suitable'
      } else if (err.name === 'StreamApiNotSupportedError') {
        error += 'Stream API is not supported in this browser'
      } else if (err.name === 'InsecureContextError') {
        error += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
      } else {
        error += err.message
      }
      console.log(error)
    },
    async getReservations(qrParsed) {
      const userid = qrParsed.userId;
      const reservationId = qrParsed.date;
      const db = getDatabase();

      let refer = ref(db, `${userid}/reservations`)
      let snapshot = await get(refer);
      if (snapshot.exists()) {
        let reservations = snapshot.val();
        for (const key in reservations) {
          if (Object.hasOwnProperty.call(reservations, key)) {
            const reservation = reservations[key];
            if (reservation.date == qrParsed.date) {

              await this.getUserById(userid)
              await this.getWorkoutsCompleted(userid)
              await this.updateReservation(qrParsed, key)
              this.lastScanned = reservation
              this.inscription = reservation

              await this.finishDailyWorkout(userid);
              return;
            }

          }
        }

      } else {
        //console.log("No data available");
      }

    },
    async updateReservation(qrParsed, idReservation) {
      const db = getDatabase();
      const userid = qrParsed.userId;
      const reservationId = idReservation;

      let refer = ref(db, `${userid}/reservations/${reservationId}`)
      let snapshot = await get(refer);

      if (snapshot.exists()) {
        let reservation = snapshot.val();

        let date = reservation.date
        date = moment(date, "YYYY-MM-DD HH:mm:ss")
        let today = moment()
        let sameDate = moment(date).isSame(today, 'day')

        if (!sameDate) {
          this.$notify({
            group: 'feedback',
            title: 'Error',
            text: 'Codigo QR no válido para hoy.',
            type: 'warning'
          });
          return;
        }

        if (!reservation.used) {
          reservation.used = true;
          await update(refer, reservation);

          if (!this.showCivilId && !this.$props.usingComponentAsAnAPI) {
            this.$notify({
              group: 'feedback',
              title: 'Exito',
              text: 'Codigo QR escaneado correctamente.',
              type: 'success'
            });
          } else {
            this.$notify({
              group: 'feedback',
              title: 'Exito',
              text: 'Asistencia registrada correctamente.',
              type: 'success'
            });
          }
          this.showCivilId = false;
          if (!this.$props.usingComponentAsAnAPI) {
            this.dialog = true;
          }
        } else {
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'Codigo QR ya utilizado hoy.',
            type: 'warning'
          });
        }

      } else {
        //console.log("No data available");
      }

    },

    async onDecode(code) {
      if (this.scanning) {
        return;
      }
      try {
        this.scanning = true;
        const decryptedData = CryptoJS.AES.decrypt(code, 'YOUSHOULDSAYNOTOJACK~!@#$%^&*()_+');
        let qrParsed = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));

        let today = moment()
        let reservationDate = moment(qrParsed.date, "YYYY-MM-DD HH:mm:ss")

        if (!today.isSame(reservationDate, 'day')) {
          this.$notify({
            group: 'feedback',
            title: 'Error',
            text: 'Codigo QR no válido para hoy.',
            type: 'warning'
          });
          return;
        }

        await this.getReservations(qrParsed)

      } catch (error) {


        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'Codigo QR invalido' + error,
          type: 'error'
        });
      } finally {
        this.scanning = false;
      }

    },
    async inscriptAndFinishDailyWorkout(id) {
      this.hideFinish = true;
      this.workingOut = false;
      if (!this.workoutDoc) return;
      const documentID = this.workoutDoc.id;
      // array of workouts
      let dates = this.workoutDates;
      const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');


      let result = await this.updateCheckIn(id, this.inscription)
      if (!result) {
        return;
      }



      //check in all the dates array if the date is the same as today in order to not duplicate the date in the array 
      let found = dates.find(elem => elem.substring(0, 10) == formattedDate.substring(0, 10))
      if (found) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Ya se registro asistencia hoy.',
          type: 'warning'
        });
        return;
      }


      // add the current date to the dates array
      dates.push(formattedDate);

      const obj = {
        day: this.workoutsCompleted + 1,
        dates: dates
      };

      const db = getFirestore();

      const docRef = doc(db, `users/${id}/workouts/${documentID}`);
      updateDoc(docRef, obj);
      this.workoutsCompleted += 1;

      let today = moment();
      await this.setAlert('checkin_no_reservation', today.toDate(), null)


      //check if plan is equal to worked out days and emit and event
      if (this.user.plan == this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Felicitaciones',
          text: 'Plan completado!! Continua asi!!',
          type: 'success'
        });
      }

      this.showCivilId = false;
    },
    async finishDailyWorkout(id) {
      this.hideFinish = true;
      this.workingOut = false;
      if (!this.workoutDoc) return;
      const documentID = this.workoutDoc.id;
      // array of workouts
      let dates = this.workoutDates;
      const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

      // add the current date to the dates array
      dates.push(formattedDate);

      const obj = {
        day: this.workoutsCompleted + 1,
        dates: dates
      };


      const db = getFirestore();

      const docRef = doc(db, `users/${id}/workouts/${documentID}`);
      updateDoc(docRef, obj);
      this.workoutsCompleted += 1;

      await this.updateCheckIn(id, this.inscription)

      //check if plan is equal to worked out days and emit and event
      if (this.user.plan == this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Plan completado!! Continua asi!!',
          type: 'success'
        });
      }
    },
    async updateCheckIn(id, schedule) {
      const user = id
      let date;
      debugger;
      if (this.inscription && schedule) {
        date = moment(schedule.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")

        // Get a reference to the schedule document
        const db = getFirestore()
        let scheduleRef = doc(db, `schedule/${date}/schedules/${schedule.id}`);

        await runTransaction(db, async transaction => {
          const doc = await transaction.get(scheduleRef);
          if (!doc.exists) {
            throw "Document does not exist!";
          }
          // Decrement the spots and add thge user to the users array
          const data = doc.data();
          let userInscription = data.users.find(elem => elem.id == user)
          userInscription.checkedIn = moment().format("YYYY-MM-DD HH:mm:ss")

          transaction.update(scheduleRef, data);
        });

        return true
      } else {
        const db = getFirestore()
        let startDate;
        let q;
        let schedules;

        if (this.$props.usingComponentAsAnAPI && this.$props.selectedSchedule) {
          let scheduleDocId = this.$props.selectedSchedule.id

          if(this.$props.selectedSchedule.startDate && this.$props.selectedSchedule.startDate.seconds){
            date = moment(this.$props.selectedSchedule.startDate.seconds * 1000).format("YYYY-MM-DD")
          } else {
            date = moment(this.$props.selectedSchedule.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
          }


          // hhere use getDOc
          const docRef = doc(db, `schedule/${date}/schedules/${scheduleDocId}`);
          const docSnap = await getDoc(docRef);
          const docData = docSnap.data()

          // map it to be in schedules array
          schedules = [{
            ...docData,
            id: docSnap.id
          }]

        } else {
          date = moment().format("YYYY-MM-DD")
          startDate = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:mm:ss")
          const schedulesRef = collection(db, `schedule/${date}/schedules`);
          q = query(schedulesRef, where("startDate", ">", startDate));
          schedules = await getDocs(q);


        }

        if (schedules.empty) {
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'No hay horarios disponibles para el día de hoy.',
            type: 'warning'
          });
          return false
        }
        let schedule;
        if (this.$props.usingComponentAsAnAPI && this.$props.selectedSchedule) {
          schedule = schedules[0]
        } else {
          schedule = {
            ...schedules.docs[0].data(),
            id: schedules.docs[0].id
          }
        }

        let userInscriptionObj = {
          id: user,
          checkedIn: moment().format("YYYY-MM-DD HH:mm:ss")
        }

        let scheduleDate;
      

        if(schedule.startDate && schedule.startDate.seconds){
          scheduleDate = moment(schedule.startDate.seconds * 1000).format("YYYY-MM-DD")
        } else {

         scheduleDate = moment(schedule.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        }
        //here use a transaction to update and not updatedoc

        let scheduleRef = doc(db, `schedule/${scheduleDate}/schedules/${schedule.id}`);

        await runTransaction(db, async transaction => {
          const doc = await transaction.get(scheduleRef);
          if (!doc.exists) {
            throw "Document does not exist!";
          }
          // Decrement the spots and add thge user to the users array

          const data = doc.data();

          if (data.spots > 0) {
            data.spots--;

            //check if user is already in the array
            let userInscription = data.users.find(elem => elem.id == user)
            if (userInscription) {
              userInscription.checkedIn = moment().format("YYYY-MM-DD HH:mm:ss")
            } else {
              data.users.push(userInscriptionObj)
            }
          } else {
            this.$notify({
              group: 'feedback',
              title: 'Atención',
              text: 'No hay cupos disponibles para el siguiente horario.',
              type: 'warning'
            });

            this.setAlert('no_spots', moment().toDate(), null)

            return false
          }



          transaction.update(scheduleRef, data);

          this.$notify({
            group: 'feedback',
            title: 'Inscripción exitosa',
            text: 'Recuerda reservar para mañana!!',
            type: 'success'
          });
        });

        return true

      }

    },
    async loginWithID() {
      try{
        //Reset all important values in data
        this.lastScanned = null;
        this.dialog = false;
        this.hideFinish = false;
        this.workingOut = false;
        this.inscription = null;
        this.workoutsCompleted = 0;
        this.workoutDates = [];
        this.workoutDoc = null;
        this.user = null;
        this.inscript = null;
        this.today = moment().format("YYYY-MM-DD");
        this.alert = false;



        this.loading = true;
        let id = this.civilId.replace(/\D/g, "");
        let ok = await this.getUserById(id)
        if (!ok) {
          this.loading = false;
          return;
        }
        ok = await this.getWorkoutsCompleted(id)
        if (!ok) {
          this.loading = false;
          return;

        }
        await this.getReservationsById(id)
        this.loading = false;

        if (this.$props.usingComponentAsAnAPI) {
          this.$emit('success', this.user)
        }

        logAuditEvent('checkin', this.$store.state.Auth.token.claims.user_id, `User ${id} checked in.`)

      } catch (error) {
        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User ${id} checkin failed.`)
      }
    },
    async loginWithIDAPI(id) {
      //Reset all important values in data
      this.lastScanned = null;
      this.dialog = false;
      this.hideFinish = false;
      this.workingOut = false;
      this.inscription = null;
      this.workoutsCompleted = 0;
      this.workoutDates = [];
      this.workoutDoc = null;
      this.user = null;
      this.inscript = null;
      this.today = moment().format("YYYY-MM-DD");
      this.alert = false;



      this.loading = true;
      let ok = await this.getUserById(id)
      if (!ok) {
        this.loading = false;
        return false;
      }
      ok = await this.getWorkoutsCompleted(id)
      if (!ok) {
        this.loading = false;
        return false;

      }
      await this.getReservationsById(id)
      this.loading = false;

      return true
    },
    async getReservationsById(id) {
      const userid = id
      const db = getDatabase();

      let today = moment();
      let refer = ref(db, `${userid}/reservations`)
      let snapshot = await get(refer);

      let hasReservation = false;

      if (snapshot.exists()) {
        let reservations = snapshot.val();
        for (const key in reservations) {
          if (Object.hasOwnProperty.call(reservations, key)) {
            const reservation = reservations[key];
            if (moment(reservation.date, 'YYYY-MM-DD HH:mm:ss').isSame(today, 'day')) {
              this.lastScanned = reservation
              this.inscription = reservation
              hasReservation = true;
              await this.updateReservation({ userId: id }, key)
              await this.finishDailyWorkout(id)
            }
          }
        }
      } else {
        //console.log("No data available");
      }

      if (!hasReservation && this.user.plan > this.workoutsCompleted) {

        await this.inscriptAndFinishDailyWorkout(id)
      } else if (!hasReservation && this.user.plan <= this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Plan completado, no se puede registrar mas asistencias esta semana.',
          type: 'warning'
        });

        await this.setAlert('plan_completed', today.toDate(), null)
      }
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
      return endOfWeek;
    },
    async getWorkoutsCompleted(id) {
      const db = getFirestore()
      const workoutsRef = collection(db, `users/${id}/workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(workoutsRef, where("weekStart", ">=", startOfWeek), where("weekStart", "<", endOfWeek));

      const workoutDocs = await getDocs(q);
      if (workoutDocs.empty) {
        this.workoutDoc = await addDoc(workoutsRef, { weekStart: startOfWeek, day: 0, dates: [] })
        this.workoutsCompleted = 0
        this.workoutDates = []
      } else {
        this.workoutDoc = workoutDocs.docs[0]
        this.workoutsCompleted = this.workoutDoc.data().day
        this.workoutDates = this.workoutDoc.data().dates

        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
        //check if plan is equal to worked out days and emit and event
        if (this.user.plan == this.workoutsCompleted) {
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'Plan completado, no se puede registrar mas asistencias.',
            type: 'warning'
          });

          await this.setAlert('plan_completed', moment().toDate(), null)
          return false

        } else {

          if (this.workoutDates.length > 0 && this.workoutDates[this.workoutDates.length - 1].substring(0, 10) == formattedDate.substring(0, 10)) {
            this.$notify({
              group: 'feedback',
              title: 'Atención',
              text: 'Ya se registro asistencia hoy.',
              type: 'warning'
            });
            return false
          } else {
            this.workingOut = true;

          }
        }
      }
      return true
    },
    async getUserById(id) {
      const db = getFirestore()
      let user = id

      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'Usuario no encontrado',
          type: 'error'
        });

        this.setAlert('user_not_found', moment().toDate(), null)

        return false;
      }

      let data = docSnap.data()

      data.plan = parseInt(data.plan)


      if (typeof data.plan !== 'undefined') {
        if (parseInt(data.plan) == 0) {
          data.plan = 6
        }
      } else {
        this.setAlert('user_plan_not_found', moment().toDate(), null)
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'Usuario no tiene plan asignado. Contacte con el administrador.',
          type: 'error'
        });

        return false
      }

      this.user = data

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'Plan vencido, no se puede registrar asistencia. Contacte con el administrador.',
            type: 'warning'
          });
          return false;
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true
          let diff = endOfSubscription.getTime() - today.getTime();
          let days = Math.ceil(diff / (1000 * 3600 * 24));
          if (days <= 5) {
            this.alert = days;
          } else {
            this.alert = false;
          }



        }
      }


      let today = moment().toDate()

      //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
      if (this.user.licensePaused) {
        //await this.setAlert('user_license_paused', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
        });

        this.setAlert('user_license_paused', today, null)
        return false;
      }

      //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
      // Query Firestore for licenses issued before today
      const licensesRef = collection(db, `users/${user}/licenses`);
      const q = query(licensesRef, where("issuedOn", "<", today));

      const licenseDocs = await getDocs(q);
      const filteredLicenses = licenseDocs.docs
        .map(doc => doc.data())
        .filter(license => {
          if (license.resumedOn) {
            return new Date(license.resumedOn.seconds * 1000) > today;
          } else if (typeof license.resumedOn === 'undefined') {
            return true;
          }

        }); // Filter by resumedOn in client

      if (filteredLicenses.length === 0) {
        //console.log('No matching documents.');
      } else {
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
        });


        this.setAlert('user_license_paused', today, null)
        return false;
      }




      return true;
    },
    async setAlert(type, date, description) {
      const db = getFirestore();

      let userID;
      if (this.user && this.user.id) {
        userID = this.user.id
      }
      else {
        userID = this.civilId
      }

      try {
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: userID,
          type: type,
          date: timestampDate,
          description: description,
          seen: false
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        // now add to the alert user subcollection
        //const alertRef = collection(db, `users/${userID}/alerts`);
        //await addDoc(alertRef, newAlert);


      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },

  }
}
</script>

<style scoped>
.v-input {
  flex: none !important;
}
</style>

<style>
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.qrcode-stream-camera {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>